import React from 'react';
import { Container } from 'react-bootstrap';
import PageTemplate from './page_template';
import MenuItem from './menu_item';
import MENU from '../assets/data/menu.json';

interface IProps {
}

interface IState {
    smallDisplay: boolean;
}

export default class Menu extends React.Component<IProps, IState> {
    showPrices: boolean;
    currentCategory: string;
    printableContainer: any;
    _ismounted: boolean;

    constructor(props: IProps) {
        super(props);
        this.showPrices = true;
        this.currentCategory = '';
        this.state = {
            smallDisplay: false,
        };
        this._ismounted = false;
        this.printableContainer = React.createRef();
        
        window.addEventListener('resize', this.handleResize);
    }

    async componentDidMount() {
        this._ismounted = true;
        this.handleResize();
    }
      
    componentWillUnmount() {
        this._ismounted = false;
    }

    handleResize = () => {
        if (this._ismounted) {
            this.setState({ smallDisplay: window.innerWidth < 680 });
        }
    }

    printDiv(page: Menu) {
        console.log(page.printableContainer);
        let win = window.open('', '');
        if (win !== null) {
            win.document.write('<html>')
            win.document.write('<head><link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous"></head>');
            // win.document.write(`<style type="text/css">${STYLES}</style>`);
            win.document.write('<body><div class="parchment">');
            win.document.write(page.printableContainer.current.innerHTML.replace('className', 'class'));
            win.document.write('</div></body></html>');
            win.document.close();
            win.print();
        }
    }

    categoryToHeader = (category: string) => {
        this.currentCategory = category;
        switch (category) {
            case 'wine':
                return 'Weine';
            case 'heurige':
                return 'Der Heurige';
            case 'side':
                return 'Beilagen';
            case 'extra':
                return 'Dazua';
            case 'soup':
                return 'Suppen';
            case 'warm':
                return 'Speisen';
            case 'vegfish':
                return 'Vegetarisches und aus\'n Wossa';
            case 'dessert':
                return 'Süßes';
            case 'coffee':
                return 'Kaffee und Tee';
        }
        return 'unknown';
    }

    renderContent = () => {
        const hasMultiSize: Array<string> = [];
        MENU.forEach((element: any) => {
            if (element.price === undefined || element.category === 'wine' || element.active === false || hasMultiSize.indexOf(element.category) > -1) {
                return;
            }
            if (element.price.small !== undefined && element.price.default !== undefined) {
                hasMultiSize.push(element.category);
            }
        });
        return React.Children.toArray(MENU.map((element: any) => {
            if (element.category === 'wine' || element.active === false) {
                return undefined;
            }
            let category = undefined;
            if (this.currentCategory !== element.category) {
                category = this.categoryToHeader(element.category);
            }
            return <MenuItem
                name={element.name}
                year={undefined}
                category={category}
                showPrices={this.showPrices && !this.state.smallDisplay}
                showSmDef={hasMultiSize.indexOf(element.category) > -1}
                description={element.description}
                descriptionEn={element.descriptionEn}
                priceSmall={element.price?.small}
                priceDefault={element.price?.default}
            />
        }));
    }

    render() {
        return (
            <PageTemplate>
                <h2><p><strong>Speisekarte</strong></p></h2>
                <div className="fstIc" style={{ paddingBottom: '10px' }}>
                    Änderungen aller Angaben vorbehalten!<br />
                    Solange der Vorrat reicht.
                </div>
                {/* <div onClick={() => this.printDiv(this)}>BLABLA</div> */}
                <Container className='parchment' ref={this.printableContainer}>
                    {this.renderContent()}
                </Container>
            </PageTemplate>
        );
    }
}
