import { object_get } from '../helper/obj_helper';

interface IPrice {
    default: number;
    small: number;
}

export default class Wine {
    id: number = -1;
    name: string = '';
    active: boolean = false;
    category: string = '';
    year: number = 1000;
    color: string = '';
    alcohol: number = 0;
    sweetness: string = '';
    price: IPrice = { default: 0, small: 0 };
    description: string[] = [];
    descriptionEn: string[] = [];

    constructor(data?: object) {
        this._internalUpdate(data);
    }

    _internalUpdate = (data?: object) => {
        this.id = object_get(data, 'id', -1);
        this.name = object_get(data, 'name', '');
        this.active = object_get(data, 'active', false);
        this.category = object_get(data, 'category', '');
        this.year = object_get(data, 'wine.year', 1000);
        this.color = object_get(data, 'wine.color', '');
        this.alcohol = object_get(data, 'wine.alcohol', 0);
        this.sweetness = object_get(data, 'wine.sweetness', '');
        this.price = object_get(data, 'price', []);
        this.description = object_get(data, 'description', []);
        this.descriptionEn = object_get(data, 'descriptionEn', []);
    }

    colorToString = (plural: boolean = false) => {
        switch (this.color) {
            case 'white':
                return plural ? 'Weißweine' : 'Weißwein';
            case 'red':
                return plural ? 'Rotweine' : 'Rotwein';
            case 'sparkling':
                return plural ? 'Sekte' : 'Sekt';
        }
        return 'Unbekannt';
    }

    sweetnessToString = () => {
        switch (this.sweetness) {
            case 'dry':
                return 'trocken';
            case 'sweet':
                return 'süß';
        }
        return 'Unbekannt';
    }
};
