// get object value via dot notation
// example key: 'result.data.accountid'
// if defaultNull is set to true every undefined return value will be set to null
export function object_get(object, key, defaultReturnValue) {
    defaultReturnValue = defaultReturnValue === undefined ? null : defaultReturnValue;

    if (object === null) {
        return defaultReturnValue;
    }

    if (typeof object !== 'object') {
        // throw new Error('No object given!');
        return defaultReturnValue;
    }

    if (typeof key === 'string') {
        return object_get(object, key.split('.'), defaultReturnValue);
    }

    if (key.length === 1) {
        return Object.prototype.hasOwnProperty.call(object, key[0])
            ? object[key[0]]
            : defaultReturnValue;
    }

    if (key.length === 0) {
        return defaultReturnValue;
    }

    return Object.prototype.hasOwnProperty.call(object, key[0])
        ? object_get(object[key[0]], key.slice(1), defaultReturnValue)
        : defaultReturnValue;
};

// set object value via dot notation
// example key: 'result.data.accountid'
export function object_set(object, key, value) {
    if (object === null) {
        return object;
    }

    if (typeof object !== 'object') {
        // throw new Error('No object given!');
        return object;
    }

    if (typeof key === 'string') {
        return object_set(object, key.split('.'), value);
    }

    if (key.length === 1) {
        if (Object.prototype.hasOwnProperty.call(object, key[0])) {
            object[key[0]] = value;
        }
        return object;
    }

    if (key.length === 0) {
        return object;
    }

    if (Object.prototype.hasOwnProperty.call(object, key[0])) {
        object[key[0]] = object_set(object[key[0]], key.slice(1), value);
    }
    return object;
};

export function sort_by (data, key, type, ascending) {
    switch (type) {
        case 'string':
            return data.sort((a, b) => {
                let x = a[key].toLowerCase();
                let y = a[key].toLowerCase();
                if (x > y) { return ascending ? 1 : -1; }
                if (x < y) { return ascending ? -1 : 1; }
                return 0;
            });
        case 'number':
            return data.sort((a, b) => {
                return ascending
                    ? a[key] - b[key]
                    : b[key] - a[key];
            });
        case 'date':
            return data.sort((a, b) => {
                let da = new Date(a[key]),
                    db = new Date(a[key]);
                return ascending ? da - db : db - da;
            });
        default:
            return data;
    }
}

const exportObject = {
    object_get,
    object_set,
    sort_by
};

export default exportObject;
