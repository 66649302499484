import React from 'react';
import CONTACT from '../assets/data/business_details.json';
import { NavLink } from 'react-router-dom';

interface IProps {
    id: string;
    theme: string;
}

interface IState {
}

export default class Footer extends React.Component<IProps, IState> {
    renderContact = () => {
        let isFirst = true;
        return React.Children.toArray(CONTACT.map((keyValue) => {
            const item = (
                <div className='footerItem'>{isFirst ? undefined : <div className='footerDot' />}{keyValue.value}</div>
            );
            isFirst = false;
            return item;
        }));
    }

    handleNavigate = () => {
        document.body.style.overflow = 'auto';
    }

    render() {
        const name = CONTACT.find((ele) => ele.name === 'name');
        return (
            <div id={this.props.id} className={`footer ${this.props.theme}`}>
                <div>
                    <div className='footerItems'>
                        <NavLink className='footerItem' onClick={this.handleNavigate} to='/impressum'>Impressum</NavLink>
                        <div className='dot10 mr10 ml10' />
                        <NavLink className='footerItem' onClick={this.handleNavigate} to='/datenschutz'>Datenschutz</NavLink>
                        <div className='dot10 mr10 ml10' />
                        <NavLink className='footerItem' onClick={this.handleNavigate} to='/settings'>Cookies</NavLink>
                    </div>
                </div>
                <div>
                    Alle Preise inkl. der gesetzl. MwSt.
                </div>
                <div>
                    <NavLink className='footerItem' onClick={this.handleNavigate} to='/kontakt'>Kontakt</NavLink>
                </div>
                <div>
                    <a className='footerItem' href="tel:+43225662287">Tischreservierung</a>
                    <div className='dot10 mr10 ml10' />
                    <a className='footerItem' href="tel:+436642643371">Ab-Hof-Verkauf</a>
                </div>
                <div>
                    <a className='footerItem' href="mailto:lokal@fischerheuriger.at">lokal@fischerheuriger.at</a>
                </div>
                <div>
                    LU/10-04-2024T17:50:22
                </div>
            </div>
        );
    }
}
