import React from 'react';
import PageTemplate from '../components/page_template';

export default class Impressum extends React.Component {
    render() {
        return (
            <PageTemplate>
                <div style={{ textAlign: 'left' }}>
                    <h2><p><strong>Impressum</strong></p></h2>
                    <p><b>Informationen und Offenlegung gemäß &sect;5 (1) ECG, &sect; 25 MedienG, &sect; 63 GewO und &sect; 14 UGB</b></p>
                    <p>
                        <b>Firmenwortlaut:</b> Weingut Richard Fischer GmbH<br />
                        <b>Firmenbuchnummer:</b> FN 116615v<br />
                        <b>Firmenbuchgericht:</b> Landesgericht Wr. Neustadt
                    </p>
                    <p>
                        <b>Anschrift:</b>Enzesfelder Straße 16, 2544 Leobersdorf
                    </p>
                    <p>
                        <b>UID-Nr:</b> ATU 17502806<br />
                        <b>Kammerzugehörigkeit:</b> Baden<br />
                        <b>Mitgliedschaften: </b>WKO 144461
                    </p>
                    <p>
                        <b>Kontaktdaten:</b><br />
                        Email: lokal@fischerheuriger.at<br />
                        Telefon: +43 (0)2256 / 62287<br />
                        Mobil: +43 (0)664 / 2643371<br />
                        Fax: +43 (0)2256 / 62360
                    </p>
                    <p>
                        <b>Haftungsbeschränkung:</b><br />
                        Trotz sorgfältiger inhaltlicher Kontrolle wird keine Haftung für die Inhalte externer Links übernommen.
                        Für den Inhalt der verlinkten Seiten sind ausschließlich deren Betreiber verantwortlich.
                        Die Urheberrechte Dritter werden mit größter Sorgfalt beachtet.<br />
                        Sollten externe Links, welche auf eine Website mit rechtswidrigen Tätigkeiten/Informationen verweisen, und/oder sollten Urheberrechtsverletzungen vorhanden sein, werden diese bei Bekanntwerden umgehend entfernen.
                    </p>
                    <p>
                        <b>Urheberrecht:</b><br />
                        Die Inhalte dieser Website sind urheberrechtlich geschützt.
                        Jegliche Verwendung bzw. Verbreitung von bereitgestelltem Material ist nicht gestattet und bedarf der schriftlichen Zustimmung des jeweiligen Rechteinhabers.
                    </p>
                </div>
            </PageTemplate>
        );
    }
}
