import React from 'react';
import DivImage from './div_image';
import PageTemplate from './page_template';

interface IProps {
}

interface IState {
    page: number;
}

export default class About extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            page: 0,
        };
    }

    getHeurige = () => {
        return (
            <div>
                <h2><p><strong>Der Heurige</strong></p></h2>
                <h5><p><strong>Unser Lokal</strong></p></h5>
                <DivImage src='heuriger.jpeg' maxWidth={400} mse='10px' mb='20px' />
                <p>Die Fichtenholzeinrichtung kombiniert mit alten Adlerziegeln verleiht unserem Lokal mit ca. 150 Sitzplätzen eine gemütliche Atmosphäre. Auch das „Stüberl“ mit 25 Sitzplätzen wird gerne von unseren Gästen für kleinere Feiern im privaten Rahmen genutzt. Durch die neue Überdachung unserer Terrasse mit ca. 100 Sitzplätzen können Sie im Sommer auch bei Nieselregen das Freie genießen.</p>
                <p>Natürlich bieten wir Ihnen unser Lokal auch außerhalb der Öffnungszeiten für Familien-. Betriebs-, Weihnachts- und Hochzeitsfeiern an. Durch ein persönliches Gespräch mit Richard Fischer jun. können Sie die Wünsche der Tischgestaltung und für Ihr Buffet besprechen. Bei Reisegruppen ab 50 Personen ersuchen wir Sie um telefonische Rücksprache.</p>
                <p>Da wir unsere Küche immer abwechslungsreich gestallten, besteht unsere Speisekarte aus Schmankerl der heimischen Heurigentradition, Spezialitäten der Saison sowie Gerichten der vegetarischen Küche.</p>
                <p>Auf Ihr Kommen freut sich die Familie Fischer.</p>
            </div>
        );
    }

    getWinzer = () => {
        return (
            <div>
                <h2><p><strong>Der Winzer</strong></p></h2>
                <p>Richard Fischer wurde 1972 in Mödling in Niederösterreich in eine traditionelle Weinbaufamilie geboren. Nach der Weinbauschule Gumpoldskirchen begann er eine Lehre als Koch im Herzoghof Baden.</p>
                <p>2001 übernahm er den elterlichen Traditionsbetrieb.</p>
                <DivImage src='richard.jpeg' maxWidth={400} mse='10px' mb='20px' />
                <h5><p><strong>Philosophie</strong></p></h5>
                <p>Qualität, Naturverbundenheit und Liebe zum Wein, das sind wesentliche Werte des Weinguts Fischer.</p>
                <p>Für uns bedeutet das, dass der Konsument beim genießen des Weines auch dessen Typizität, das Klima und die Lage, die Sorgfalt der Weingartenarbeit und der Vinifizierung spüren kann.</p>
                <p>Ich will keine verwechselbare internationale Modeweine, sondern ganz typisch Nieder-/Österreichische Top-Qualität erschaffen. Weinmachen ist für mich das größte Glück, für mich verbinden sich dabei Beruf und Hobby. Ich kann mir nichts Schöneres vorstellen.</p>
            </div>
        );
    }

    getWeingut = () => {
        return (
            <div>
                <h2><p><strong>Das Weingut</strong></p></h2>
                <p>Seit 1953 führen wir den Weinbaubetrieb in der Enzesfelderstraße 16.</p>
                <p>Die Weingärten befinden sich am Lindenberg, wobei die Bodenbeschaffenheit von lehmig bis steinig reicht.</p>
                <DivImage src='keller.jpeg' maxWidth={400} mse='10px' mb='20px' />
                <h5><p><strong>Die Weinlese</strong></p></h5>
                <p>Der richtige Zeitpunkt bestimmt die Weinqualität.</p>
                <p>Die Weinlese beginnt bei uns etwa im September. Das Traubenmaterial wird von uns, unseren Erntehelfern sowie den Vertragswinzern von Hand gelesen. Aber auch im Frühjahr herrscht in den Weingärten absolute Hochsaison. Etwa im Juni wird begonnen die unerwünschten Triebe des Weinstocks zu entfernen damit der Wuchs gefördert wird. Auch werden die Fruchtansätze abgeschnitten um im Herbst eine gute Qualität zu erzielen.</p>
                <DivImage src='glas.jpeg' maxWidth={400} mse='10px' mb='20px' />
                <p>Im Weinbau sind viele Arbeitsgänge notwendig bis die Flasche schlussendlich beim Weintrinker ankommt. Dank intensiver Forschung ist es gelungen die Weinqualität ganz entscheidend zu verbessern. Somit gab es noch nie so gute und qualitativ hochwertige Weine wie heute.</p>
            </div>
        );
    }

    render() {
        return (
            <PageTemplate>
                <div className='aboutLinks'>
                    <div onClick={() => {this.setState({page: 0});}} className={this.state.page === 0 ? 'aboutLinkSelected' : 'aboutLink'}>
                        Heurige
                    </div>
                    <strong>&#x2022;</strong>
                    <div onClick={() => {this.setState({page: 1});}} className={this.state.page === 1 ? 'aboutLinkSelected' : 'aboutLink'}>
                        Winzer
                    </div>
                    <strong>&#x2022;</strong>
                    <div onClick={() => {this.setState({page: 2});}} className={this.state.page === 2 ? 'aboutLinkSelected' : 'aboutLink'}>
                        Weingut
                    </div>
                </div>
                {
                    this.state.page === 0
                        ? this.getHeurige()
                        : this.state.page === 1
                            ? this.getWinzer()
                            : this.state.page === 2
                                ? this.getWeingut()
                                : undefined
                }
            </PageTemplate>
        );
    }
}
