import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import PageTemplate from '../components/page_template';
import CONTACT from '../assets/data/business_details.json';
import TEXT from '../assets/data/text_contact.json';

interface IProps {
}

export default class Contact extends React.Component<IProps> {
    constructor(props: IProps) {
        super(props);
    }

    async componentDidMount() {
        window.addEventListener('resize', () => {
            this.setState({});
        });
    }

    renderContact = () => {
        let useXs = window.innerWidth < 560;
        return React.Children.toArray(CONTACT.map((keyValue) => {
            return (
                <Row>
                    <Col xs={4} style={{ textAlign: 'right' }}>{useXs ? keyValue.keyXs : keyValue.key}:</Col>
                    <Col xs={8} style={{ textAlign: 'left' }}>{keyValue.value}</Col>
                </Row>
            );
        }));
    }

    getText = () => {
        let htmlText = '';
        TEXT.forEach((line) => {
            htmlText += line;
        });
        return htmlText;
    }

    render() {
        return (
            <PageTemplate>
                {/* <h2><p><strong>Kontakt</strong></p></h2>
                <p>Angaben gemäß §5 Abs.1 ECG (E-Commerce Gesetz) | Österreich</p> */}
                <div dangerouslySetInnerHTML={{__html: this.getText()}}></div>
                <Container>
                    {this.renderContact()}
                </Container>
            </PageTemplate>
        );
    }
}
