import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import PageTemplate from './page_template';
import LUNCH from '../assets/data/lunch_deal.json';

const HORIZONTAL_BAR = '<div style="color:black; background-color:black; height:2px; width:50px; display:block; margin-top:5px; margin-bottom:5px; margin-left:auto; margin-right:auto;"></div>';

interface IProps {
}

interface IState {
    isLoading: boolean;
}

export default class LunchDealComponent extends React.Component<IProps, IState> {
    showPrices: boolean;
    currentColor: string;

    constructor(props: IProps) {
        super(props);
        this.showPrices = false;
        this.currentColor = '';
        this.state = {
            isLoading: true,
        };
    }

    async componentDidMount() {
        this.setState({
            isLoading: false,
        });
    }

    getMonthText = (month: Number) => {
        switch (month) {
            case 1:
                return 'Januar';
            case 2:
                return 'Februar';
            case 3:
                return 'März';
            case 4:
                return 'April';
            case 5:
                return 'Mai';
            case 6:
                return 'Juni';
            case 7:
                return 'Juli';
            case 8:
                return 'August';
            case 9:
                return 'September';
            case 10:
                return 'Oktober';
            case 11:
                return 'November';
            case 12:
                return 'Dezember';
        }
        return 'Unbekannt';
    }

    renderContent = () => {
        return React.Children.toArray(LUNCH.map((element: any) => {
            let dateAry: Array<string> = [];
            let itemClass = 'lunchDealItem';
            element.date.forEach((dateString: string) => {
                dateAry = dateString.split('.');
                let date = new Date(Number(dateAry[2]), Number(dateAry[1]) - 1, Number(dateAry[0]));
                if (date.toLocaleDateString() === (new Date()).toLocaleDateString()) {
                    itemClass = 'lunchDealItemNow';
                }
            });

            if (element.date.length > 1) {
                let nameText = element.name;
                if (element.name.includes(';')) {
                    let splittedName = element.name.split(';');
                    let lastName = '';
                    nameText = '';
                    splittedName.forEach((splitName: string) => {
                        if (lastName === '' && splitName !== '') {
                            nameText += HORIZONTAL_BAR + splitName + '<br />';
                        } else if (lastName !== '' && splitName !== '') {
                            nameText += splitName + '<br />';
                        }
                        lastName = splitName;
                    });
                    nameText += HORIZONTAL_BAR;
                }
                return (
                    <Row className={itemClass}>
                        <Col>
                            <div className='fstIc' dangerouslySetInnerHTML={{__html: nameText}}></div>
                        </Col>
                    </Row>
                );
            } else {
                return (
                    <Row className={itemClass}>
                        <Col md='auto' className='lunchDealColumn left'>
                            {dateAry[0]}.{dateAry[1]}.
                        </Col>
                        <Col>
                            {element.name}
                        </Col>
                        <Col md='auto' className='lunchDealColumn'>
                            {element.price ? ('€ ' + Number(element.price).toFixed(2)) : undefined}
                        </Col>
                    </Row>
                );
            }
        }));
    }

    render() {
        let dtFromTxt = LUNCH[0];
        let dtToTxt = LUNCH[LUNCH.length - 1];
        let dtFromStr = 'Unbekannt';
        let dtToStr = 'Unbekannt';
        if (dtFromTxt.date && dtToTxt.date) {
            let dtFromAry = dtFromTxt.date[0].split('.');
            let dtToAry = dtToTxt.date[dtToTxt.date.length - 1].split('.');
            dtFromStr = dtFromAry[0] + '. ' + this.getMonthText(Number(dtFromAry[1])) + ' ' + dtToAry[2];
            dtToStr = dtToAry[0] + '. ' + this.getMonthText(Number(dtToAry[1])) + ' ' + dtToAry[2];
        }
        return (
            <PageTemplate isLoading={this.state.isLoading}>
                <h2><p><strong>Mittagsmenü mit Suppe</strong></p></h2>
                <b>{dtFromStr} bis {dtToStr}</b><br /><br />
                <div className='fstIc' style={{ paddingBottom: '10px' }}>
                    Änderungen aller Angaben vorbehalten!<br />
                    Solange der Vorrat reicht.<br />
                    Auch kleine Portionen möglich. - €2.00 
                </div>
                <Container className='parchment'>
                    {this.renderContent()}
                </Container>
            </PageTemplate>
        );
    }
}
