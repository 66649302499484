import React from 'react';
import { NavLink } from 'react-router-dom';
import { GiHamburgerMenu, GiWineBottle } from 'react-icons/gi';
import { ImCross } from 'react-icons/im';
import DivImage from './div_image';

interface IProps {
    id: string;
    theme: string;
}

interface IState {
    showNavbar: boolean;
}

export default class Navbar extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            showNavbar: false
        };
    }

    handleShowNavbar = () => {
        let showIt = !this.state.showNavbar;
        document.body.style.overflow = showIt ? 'hidden' : 'auto';
        this.setState({
            showNavbar: showIt
        });
    }

    handleNavigate = () => {
        document.body.style.overflow = 'auto';
    }

    handleShowRoot = () => {
        window.location.href = '/';
    }

    render() {
        return (
            <div id={this.props.id} className={`navbar ${this.props.theme}`}>
                <img
                    style={{ maxHeight: '27px' }}
                    className={`logo ${this.props.theme}`}
                    src={require('../assets/images/fischer_logo.png')}
                    alt='fischer_logo.png'
                    onClick={this.handleShowRoot}
                />
                {
                    this.state.showNavbar
                        ? <ImCross className='menuIcon' size='1.5em' onClick={this.handleShowNavbar} />
                        : <GiHamburgerMenu className='menuIcon' size='1.5em' onClick={this.handleShowNavbar} />
                }
                <div className={`navelements ${this.props.theme} ${this.state.showNavbar && 'active'}`} onClick={() => {this.state.showNavbar && this.handleShowNavbar()}}>
                    <ul>
                        <li>
                            <NavLink onClick={this.handleNavigate} to='/'>Willkommen</NavLink>
                        </li>
                        <li>
                            <NavLink onClick={this.handleNavigate} to='/ueber'>Über uns</NavLink>
                        </li>
                        <li>
                            <NavLink onClick={this.handleNavigate} to='/weine'>Weine</NavLink>
                        </li>
                        <li>
                            <NavLink onClick={this.handleNavigate} to='/mittagsmenue'>Mittagsmenü</NavLink>
                        </li>
                        <li>
                            <NavLink onClick={this.handleNavigate} to='/speisekarte'>Speisekarte</NavLink>
                        </li>
                        <li>
                            <NavLink onClick={this.handleNavigate} to='/anfahrt'>Anfahrt</NavLink>
                        </li>
                        <li>
                            <NavLink onClick={this.handleNavigate} to='/bilder'>Bilder</NavLink>
                        </li>
                    </ul>
                </div>
            </div>
        );
    }
}
