import React from 'react';
import { Container } from 'react-bootstrap';
import PageTemplate from './page_template';
import MenuItem from './menu_item';
import Wine from '../models/wine';
import MENU from '../assets/data/menu.json';

interface IProps {
}

interface IState {
    smallDisplay: boolean;
}

export default class Wines extends React.Component<IProps, IState> {
    showPrices: boolean;
    currentColor: string;
    _ismounted: boolean;

    constructor(props: IProps) {
        super(props);
        this.showPrices = true;
        this.currentColor = '';
        this.state = {
            smallDisplay: false,
        };
        this._ismounted = false;

        window.addEventListener('resize', this.handleResize);
    }

    async componentDidMount() {
        this._ismounted = true;
        this.handleResize();
    }
      
    componentWillUnmount() {
        this._ismounted = false;
    }

    handleResize = () => {
        if (this._ismounted) {
            this.setState({ smallDisplay: window.innerWidth < 680 });
        }
    }

    renderWineList = () => {
        let wines: Wine[] = [];
        MENU.forEach((element: any) => {
            const wine = new Wine(element);
            if (wine.category !== 'wine') {
                return;
            }
            wines.push(wine);
        });

        wines.sort((a, b) => {
            if (a.color === 'white') { return -3; }
            if (a.color === 'red') { return -2; }
            if (a.color > b.color) { return 1; }
            if (b.color < a.color) { return -1; }
            return 0;
        });

        return React.Children.toArray(wines.map((wine: Wine) => {
            if (wine.active == false) {
                return;
            }
            let color = undefined;
            if (this.currentColor !== wine.color) {
                this.currentColor = wine.color;
                color = wine.colorToString(true);
            }
            return <MenuItem
                name={wine.name}
                year={wine.year}
                category={color}
                showPrices={this.showPrices && !this.state.smallDisplay}
                showSmDef={false}
                description={wine.description}
                descriptionEn={wine.descriptionEn}
                descriptionExtra={wine.alcohol
                    ? 'Süßegrad: ' + wine.sweetnessToString() + ', Alk.: ' + wine.alcohol + ' Vol.-%'
                    : undefined
                }
                priceSmall={wine.price?.small}
                priceDefault={wine.price?.default}
            />;
        }));
    }

    render() {
        return (
            <PageTemplate>
                <h2><p><strong>Weine</strong></p></h2>
                <div className="fstIc" style={{ paddingBottom: '10px' }}>
                    Änderungen aller Angaben vorbehalten!<br />
                    Solange der Vorrat reicht.
                </div>
                <Container className='parchment'>
                    {this.renderWineList()}
                </Container>
            </PageTemplate>
        );
    }
}
