import React from 'react';
import { Alert, Spinner } from 'react-bootstrap';
import Navbar from '../components/navbar';
import Footer from '../components/footer';
import Cookiebar from '../components/cookiebar';

interface IProps {
    isLoading?: boolean;
    alertMsg?: string | undefined;
    children?: React.ReactNode;
    getCookies?: (cookies: string) => void;
    getTheme?: (theme: string) => void;
}

interface IState {
    cookies: string;
    theme: string;
    background: string;
    showCookiebar: boolean;
    changecookies: boolean;
}

export default class PageTemplate extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            cookies: '',
            theme: 'earth',
            background: 'bg_earth',
            showCookiebar: true,
            changecookies: false,
        };
    }

    async componentDidMount() {
        this.refreshComponent();
        this.setScrollPosition();
    }

    refreshComponent = () => {
        const cookies = window.sessionStorage.getItem('cookies') ?? '';
        let theme = window.sessionStorage.getItem('theme') ?? undefined;
        let backgd = window.sessionStorage.getItem('background') ?? undefined;
        if (!theme) {
            theme = 'earth';
            window.sessionStorage.setItem('theme', theme);
        }
        if (!backgd) {
            backgd = 'bg_earth';
            window.sessionStorage.setItem('background', backgd);
        }
        this.setState({
            cookies: cookies,
            theme: theme,
            background: backgd,
            showCookiebar: cookies.indexOf('cookieshandled') < 0,
            changecookies: false,
        });
        if (this.props.getCookies) {
            this.props.getCookies(cookies);
        }
        if (this.props.getTheme) {
            this.props.getTheme(theme);
        }
    }

    // componentDidUpdate() {
    //     this.setScrollPosition();
    // }

    setScrollPosition = () => {
        const navbar = document.getElementById('navbar');
        if (!navbar) {
            return;
        }
        window.scrollTo({
            top: navbar.offsetTop
        });
    }

    renderContent = () => {
        if (this.props.isLoading) return <Spinner />;
        if (this.props.alertMsg) return (
            <Alert variant='danger'>
                {this.props.alertMsg}
            </Alert>
        );
        return this.props.children;
    }

    render() {
        return (
            <>
                <Navbar id='navbar' theme={this.state.theme} />
                <div className={`pageTemplate ${this.state.theme} ${this.state.background}`}>
                    {this.renderContent()}
                    <Footer id='footer' theme={this.state.theme} />
                </div>
                {
                    this.state.showCookiebar
                        ? <Cookiebar id='cookiebar' theme={this.state.theme} onChange={() => { this.refreshComponent(); }} />
                        : undefined
                }
            </>
        );
    }
};
