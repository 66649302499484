import React from 'react';
import { NavLink } from 'react-router-dom';
import PageTemplate from '../components/page_template';
import DivImage from './div_image';
import Calendar from './calendar';

interface IProps {
}

interface IState {
    showVideo: boolean;
    theme: string;
    videoBlur: number;
}

export default class Welcome extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            showVideo: false,
            theme: 'default',
            videoBlur: 2,
        };
    }

    async componentDidMount() {
        window.addEventListener('resize', () => {
            this.setState({});
        });
    }

    handleNavigate = () => {
        document.body.style.overflow = 'auto';
    }

    render() {
        const ratio = 640 / 360;
        let useWidth = window.innerWidth > 640 ? 635 : window.innerWidth - 5;
        let useHeight = window.innerWidth > 640 ? 360 : ((window.innerWidth - 5) / ratio) - (5  / ratio);
        let playBtnSize = window.innerWidth > 640 ? 200 : (window.innerWidth / (640 / 200));
        return (
            <PageTemplate getTheme={(theme: string) => { this.setState({ theme: theme }); }}>
                <br /><h2><strong>Herzlich Willkommen beim</strong></h2>
                <div className='welcomeName'>Heurigen Fischer</div>
                <h2><p><strong>in Leobersdorf</strong></p></h2>
                <div className='welcomeNavlinks mb-2'>
                    <NavLink className={`welcomeNavlink`} onClick={this.handleNavigate} to='/weine'>Weine</NavLink>
                </div>
                <div className='welcomeNavlinks'>
                    <NavLink className={`welcomeNavlink`} onClick={this.handleNavigate} to='/mittagsmenue'>Mittagsmenü</NavLink>
                    <div className='dot20 ml10 mr10' />
                    <NavLink className={`welcomeNavlink`} onClick={this.handleNavigate} to='/speisekarte'>Speisen</NavLink>
                </div>
                <div className='welcomeVideo'>
                    {
                        this.state.showVideo
                            ? <video
                                onClick={() => this.setState({ showVideo: false })}
                                autoPlay={true}
                                style={{
                                    height: useHeight,
                                    width: useWidth,
                                    minHeight: useHeight,
                                    minWidth: useWidth,
                                    maxHeight: useHeight,
                                    maxWidth: useWidth,
                                }}
                                onEnded={() => this.setState({ videoBlur: 0, showVideo: false })}
                            >
                                <source src={require('../assets/videos/Therminator.mp4')} type='video/mp4' />
                            </video>
                            : <DivImage
                                blur={this.state.videoBlur}
                                useImage={true}
                                src='therminator.png'
                                dimensions={{ height: useHeight, width: useWidth }}
                                onClick={() => this.setState({ showVideo: true })}
                                content={
                                    this.state.videoBlur === 2
                                        ? <img
                                            style={{ position: 'absolute' }}
                                            width={playBtnSize}
                                            height={playBtnSize}
                                            src={require('../assets/images/play_light.png')}
                                            alt='therminator.jpg'
                                        />
                                        : undefined
                                }
                            />
                    }
                </div>
                <p className='fs20'>Ausg'steckt is'</p>
                <p className='fwtBd fs20'>FISCHER HEURIGER</p>
                { Calendar(this.state.theme) }
                <br />
                <p>Wir haben in diesem Zeitraum täglich von 11 bis 22 Uhr geöffnet.</p>
                <p>Wir freuen uns auf Ihren Besuch!</p>
            </PageTemplate>
        );
    }
}
