import React from 'react';
import PageTemplate from '../components/page_template';
import { Button, Container, Form } from 'react-bootstrap';

interface IProps {
}

interface IState {
    theme: string;
    background: string;
    changecookies: boolean;
}

export default class Settings extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            theme: 'default',
            background: 'black',
            changecookies: false,
        };
    }

    async componentDidMount() {
        this.setState({
            theme: window.sessionStorage.getItem('theme') ?? 'default',
            background: window.sessionStorage.getItem('background') ?? 'black',
            changecookies: false,
        });
    }

    cookieOnClick = () => {
        let cookies = window.sessionStorage.getItem('cookies') ?? '';
        if (cookies.indexOf('cookieshandled') > -1) {
            cookies = cookies.replace('cookieshandled', '');
        }
        window.sessionStorage.setItem('cookies', cookies);
        this.setState({ changecookies: true });
    }

    themeOnChange = (object: any) => {
        window.sessionStorage.setItem('theme', object.target.id);
        this.setState({ theme: object.target.id });
    }

    backgroundOnChange = (object: any) => {
        window.sessionStorage.setItem('background', object.target.id);
        this.setState({ background: object.target.id });
    }

    render() {
        return (
            <PageTemplate key={this.state.theme + this.state.background + this.state.changecookies}>
                <h2><p><strong>Settings</strong></p></h2>
                <Container className='parchment'>
                    <div style={{ display: 'flex', justifyContent: 'left' }}>
                        <h3><strong>Cookies:</strong></h3>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'left' }}>
                        <Button variant='info' onClick={this.cookieOnClick}>Change Cookies</Button>
                    </div>

                    {/* <div style={{ display: 'flex', justifyContent: 'left', marginTop: '15px' }}>
                        <h3><strong>Theme:</strong></h3>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'left' }}>
                        <Form.Check type='radio' name='group_theme' onChange={this.themeOnChange} id='black' label='Schwarz' checked={this.state.theme === 'black'} />
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'left' }}>
                        <Form.Check type='radio' name='group_theme' onChange={this.themeOnChange} id='white' label='Weiß' checked={this.state.theme === 'white'} />
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'left' }}>
                        <Form.Check type='radio' name='group_theme' onChange={this.themeOnChange} id='earth' label='Erdig' checked={this.state.theme === 'earth'} />
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'left', marginTop: '15px' }}>
                        <h3><strong>Hintergrund:</strong></h3>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'left' }}>
                        <Form.Check type='radio' name='group_background' onChange={this.backgroundOnChange} id='bg_black' label='Schwarz' checked={this.state.background === 'bg_black'} />
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'left' }}>
                        <Form.Check type='radio' name='group_background' onChange={this.backgroundOnChange} id='bg_white' label='Weiß' checked={this.state.background === 'bg_white'} />
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'left' }}>
                        <Form.Check type='radio' name='group_background' onChange={this.backgroundOnChange} id='bg_earth' label='Erdig' checked={this.state.background === 'bg_earth'} />
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'left' }}>
                        <Form.Check type='radio' name='group_background' onChange={this.backgroundOnChange} id='bg_dots' label='Punkte' checked={this.state.background === 'bg_dots'} />
                    </div> */}
                </Container>
            </PageTemplate>
        );
    }
}
