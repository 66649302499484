import React from 'react';
import { Row, Col } from 'react-bootstrap';

interface IProps {
    showPrices?: boolean | undefined;
    showSmDef?: boolean | undefined;
    category?: string | undefined;
    name: string;
    year: number | undefined;
    description?: Array<string> | undefined;
    descriptionEn?: Array<string> | undefined;
    descriptionExtra?: string | undefined;
    priceSmall?: number | undefined;
    priceDefault?: number | undefined;
}

export default class Menu extends React.Component<IProps> {
    getHeader = () => {
        if (!this.props.category) {
            return undefined;
        }
        return (
            <>
                <Row className="itemHeader">
                    <Col>{this.props.category}</Col>
                </Row>
                {
                    this.props.showPrices && this.props.showSmDef
                        ? <Row className="justify-content-md-center" style={{ marginLeft: '5px', marginRight: '5px', paddingBottom: "5px" }}>
                                <Col></Col>
                                <Col md='auto' className='itemPrice'>klein</Col>
                                <Col md='auto' className='itemPrice'>normal</Col>
                            </Row>
                        : undefined
                }
            </>
        );
    }

    render() {
        return (
            <div>
                {this.getHeader()}
                <Row style={{ marginLeft: '5px', marginRight: '5px', marginTop: '5px' }}>
                    <Col>
                        <Row className='itemName'>{this.props.name}{this.props.year ? ' ' + this.props.year : ''}</Row>
                        {
                            this.props.descriptionExtra
                                ? <Row className="itemDescEn">{this.props.descriptionExtra}</Row>
                                : ''
                        }
                        {
                            this.props.description
                                ? React.Children.toArray(this.props.description.map((desc: any) => {
                                        return <Row className='itemDesc'>{desc}</Row>;
                                    }))
                                : ''
                        }
                        {
                            this.props.descriptionEn
                                ? React.Children.toArray(this.props.descriptionEn.map((desc: any) => {
                                        return <Row className='itemDescEn'>{desc}</Row>;
                                    }))
                                : ''
                        }
                    </Col>
                    {
                        this.props.showPrices
                            ? <Col md='auto' className='itemPrice'>
                                    {
                                        this.props.priceSmall
                                            ? `€ ${this.props.priceSmall.toFixed(2)}`
                                            : ''
                                    }
                                </Col>
                            : undefined
                    }
                    {
                        this.props.showPrices
                            ? <Col md='auto' className='itemPrice'>
                                    {
                                        this.props.priceDefault
                                            ? `€ ${this.props.priceDefault.toFixed(2)}`
                                            : ''
                                    }
                                </Col>
                            : undefined
                    }
                </Row>
            </div>
        );
    }
}
