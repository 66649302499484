import React from 'react';
import PageTemplate from '../components/page_template';

export default class Datenschutz extends React.Component {
    render() {
        return (
            <PageTemplate>
                <div style={{ textAlign: 'left' }}>
                    <h2><p><strong>Datenschutzerklärung</strong></p></h2>
                    <p>
                        <b>Kontaktdaten:</b><br />
                        Weingut Richard Fischer GmbH<br />
                        Enzesfelder Straße 16<br />
                        2544 Leobersdorf<br />
                        Email: lokal@fischerheuriger.at<br />
                        Telefon: +43 (0)2256 / 62287<br />
                        Mobil: +43 (0)664 / 2643371<br />
                        Fax: +43 (0)2256 / 62360
                    </p>
                    <p>
                        <b>Verwendung von Cookies:</b><br />
                        Auf verschiedenen Seiten verwenden wir Cookies, um den Besuch unserer Website attraktiv zu gestalten und die Nutzung bestimmter Funktionen zu ermöglichen.
                        Hierbei handelt es sich um kleine Textdateien, die auf Ihrem Endgerät abgelegt werden. Sie richten keinen Schaden an.
                        Die meisten der von uns verwendeten Cookies werden nach Ende der Browser-Sitzung, also nach Schließen Ihres Browsers, wieder gelöscht (sog. Sitzungs-Cookies).
                        Andere Cookies verbleiben auf Ihrem Endgerät und ermöglichen uns oder unseren Partnerunternehmen, Ihren Browser bei Ihrem nächsten Besuch wiederzuerkennen (sog. persistente Cookies).
                        Wenn Sie dies nicht wünschen, so können Sie Ihren Browser so einrichten, dass er Sie über das Setzen von Cookies informiert und Sie dies nur im Einzelfall erlauben. Bei der Deaktivierung von Cookies kann die Funktionalität unserer Website eingeschränkt sein.
                    </p>
                    <p>
                        <b>Google Maps:</b><br />
                        Unsere Website verwendet Funktionen des Webkartendienstes „Google Maps“. Der Dienstanbieter dieser Funktion ist:<br />
                        <div className='dot10 ml10 mr10' /> Google Ireland Limited Gordon House, Barrow Street Dublin 4. Ireland. Tel: +353 1 543 1000<br />
                        Im Zuge der Nutzung von Google Maps ist es notwendig Ihre IP-Adresse zu speichern und zu verarbeiten.
                        Google überträgt in der Regel an einen Server in den USA und speichert die Daten dort.
                        Die Verarbeitung geschieht durch den Diensteanbieter (oben genannt), der Betreiber dieser Homepage hat keinen Einfluss auf die Übertragung der Daten.<br />
                        Die Datenverarbeitung erfolgt auf Basis der gesetzlichen Bestimmungen des § 96 Abs 3 TKG sowie des Art 6 Abs 1 lit f (berechtigtes Interesse) der DSGVO.
                        Die Nutzung von Google Maps erhöht die Auffindbarkeit der Orte, welche auf unserer Webseite bereitgestellt werden.
                        Weitere Informationen über den Umgang mit Nutzerdaten des Diensteanbieters „Google“ können Sie der <a href='https://policies.google.com/privacy?hl=de'>Datenschutzerklärung</a> entnehmen.
                        Google verarbeitet die Daten auch in den USA, hat sich jedoch dem <a href='https://www.privacyshield.gov/EU-US-Framework'>EU-US Privacy-Shield</a> unterworfen.
                    </p>
                </div>
            </PageTemplate>
        );
    }
}
