import React from 'react';
import DATES from '../assets/data/business_hours.json';

interface IYear {
    year: number;
    cntAll: number;
    cntPast: number;
}

interface ICalendarEntry {
    position: string;
    year: string;
    from: string;
    to: string;
}

interface ICalendar {
    isAusgsteckt: boolean;
    visibleYears: Array<string>;
    entries: Array<ICalendarEntry>;
}

function getCalendarData (): ICalendar {
    const currentDate = new Date();
    let isAusgsteckt = false;
    let visibleYears: Array<string> = [];
    const years: Array<IYear> = [];

    const mappedDates = DATES.map((dates) => {
        const dateFromAry = (dates.from + dates.year).split('.');
        const dateToAry = (dates.to + dates.year).split('.');
        const dateFrom = new Date(Number(dateFromAry[2]), Number(dateFromAry[1]) - 1, Number(dateFromAry[0]), 0, 0, 0);
        const dateTo = new Date(Number(dateToAry[2]), Number(dateToAry[1]) - 1, Number(dateToAry[0]), 23, 59, 59);

        let year = years.find((year) => year.year === Number(dateFromAry[2]));
        if (!year) {
            year = {
                year: Number(dateFromAry[2]),
                cntAll: 0,
                cntPast: 0,
            };
            years.push(year);
        }
        year.cntAll++;

        let value: { position: string; year: string; from: string; to: string } = {
            position: 'past',
            year: dates.year,
            from: dates.from,
            to: dates.to
        };

        if (dateFrom <= currentDate && currentDate <= dateTo) {
            value.position = 'now';
            isAusgsteckt = true;
        } else if (dateFrom > currentDate) {
            value.position = 'future';
        }

        if (value.position === 'past') {
            year.cntPast++;
        }

        return value;
    });

    years.forEach(year => {
        if (year.cntAll !== year.cntPast) {
            visibleYears.push(String(year.year));
        }
    });

    return {
        isAusgsteckt: isAusgsteckt,
        visibleYears: visibleYears,
        entries: mappedDates
    };
};

const Calendar = (theme: string) => {
    const calendarData = getCalendarData();
    let lastYear = '';
    return (<>
        {
            calendarData.isAusgsteckt
                ? undefined
                : <><div className={`calendarNow ${theme}`}>Aktuell NICHT geöffnet!</div><br /></>
        }
        <div className='calendarCenter'>
            <div className='calendarCard'>
            {
                React.Children.toArray(calendarData.entries.map((mappedDate) => {
                    if (calendarData.visibleYears.indexOf(mappedDate.year) === -1) {
                        return undefined;
                    }
                    let showYear = false;
                    if (lastYear !== mappedDate.year) {
                        lastYear = mappedDate.year;
                        showYear = true;
                    }
                    let nameOfClass = 'calendar';
                    switch (mappedDate.position) {
                        case 'past':
                            nameOfClass = 'calendarPast';
                            break;
                        case 'now':
                            nameOfClass = 'calendarNow';
                            break;
                    }
                    return (<>
                        {
                            showYear
                                ? <div style={{ fontWeight: 'bold' }}>{mappedDate.year}</div>
                                : undefined
                        }
                        {
                            mappedDate.position === 'now'
                                ? <div className={`calendarNow top`}>Aktuell geöffnet!</div>
                                : undefined
                        }
                        <div className={`${nameOfClass} bottom`}>{`${mappedDate.from} - ${mappedDate.to}`}</div>
                    </>);
                }))
            }
            </div>
        </div>
    </>);
}

export default Calendar;
