import React from 'react';
import PageTemplate from '../components/page_template';

interface IProps {
}

interface IState {
    cookies: string;
}

export default class Contact extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            cookies: '',
        };
    }

    async componentDidMount() {
        window.addEventListener('resize', () => {
            this.setState({});
        });
    }

    render() {
        let width = 800;
        let height = 600;
        // style.css media calculation for small displays
        let innerWidth = window.innerWidth - (window.innerWidth < 680 ? 10 : 200);
        if (innerWidth < 820) {
            width = innerWidth - 20;
            height = width * 0.75;
        }
        return (
            <PageTemplate getCookies={(cookies: string) => { this.setState({ cookies: cookies }); }}>
                <h2><p><strong>Anfahrt</strong></p></h2>
                <p>So kommen Sie zu uns …</p>
                <p>… Über die Autobahn: Abfahrt Leobersdorf.</p>
                <p>… Über die B18 : Kreisverkehr Auffahrt Autobahn.</p>
                <p><strong>
                    Enzesfelder Straße 16<br />
                    2544 Leobersdorf<br />
                    Österreich
                </strong></p>
                <p>
                    … Kreisverkehr{' –> Richtung Europastraße'}<br />
                    {'–> nächster Kreisverkehr'}<br />
                    {'–> Richtung Enzesfelderstraße'}<br />
                    {'–> unter die Autobahn'}<br />
                    {'–> ca. 150m auf der linken Seite'}
                </p>
                {
                    this.state.cookies.indexOf('googlemaps') > -1
                        ? <iframe
                            title='google-iframe'
                            src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d668.3410563401454!2d16.21173396959005!3d47.92933799820133!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x476db700428b7fb5%3A0x7d008f29602aa8c5!2sHeuriger%20Fischer!5e0!3m2!1sde!2sat!4v1684937534283!5m2!1sde!2sat'
                            width={`${width}px`}
                            height={`${height}px`}
                            style={{ border: '0px' }}
                            allowFullScreen={false}
                            loading='lazy'
                            referrerPolicy='no-referrer-when-downgrade'
                        />
                        : <div className='googleMapsCookie'>Die Erlaubnis für Google Maps muss über die Cookie-Einstellungen gesetzt werden!</div>
                }
            </PageTemplate>
        );
    }
}
