import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import './style.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { createRoot } from 'react-dom/client';

import Welcome from './components/welcome';
import Pictures from './components/pictures';
import Ueber from './components/about';
import Kontakt from './components/contact';
import Anfahrt from './components/anfahrt';
import LunchDeal from './components/lunch_deal';
import Menu from './components/menu';
import Wines from './components/wines';
import NotFound from './components/not_found';
import Impressum from './components/impressum';
import Datenschutz from './components/datenschutz';
import Settings from './components/settings';

const rootElement = document.getElementById('root');
if (rootElement) {
    const root = createRoot(rootElement!);
    root.render(
        <React.StrictMode>
            <BrowserRouter basename='/'>
                <Routes>
                    <Route path='/' element={<Welcome />} />
                    <Route path='/ueber' element={<Ueber />} />
                    <Route path='/kontakt' element={<Kontakt />} />
                    <Route path='/anfahrt' element={<Anfahrt />} />
                    <Route path='/bilder' element={<Pictures />} />
                    <Route path='/weine' element={<Wines />} />
                    <Route path='/mittagsmenue' element={<LunchDeal />} />
                    <Route path='/speisekarte' element={<Menu />} />
                    <Route path='/impressum' element={<Impressum />} />
                    <Route path='/datenschutz' element={<Datenschutz />} />
                    <Route path='/settings' element={<Settings />} />
                    <Route path='/*' element={<NotFound />} />
                </Routes>
            </BrowserRouter>
        </React.StrictMode>
    );
}
