import React from 'react';
import { Form, Button } from 'react-bootstrap';

interface IProps {
    id: string;
    theme: string;
    onChange?: () => void;
}

interface IState {
    cookies: string;
}

export default class Cookiebar extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            cookies: '',
        };
    }

    async componentDidMount() {
        this.setState({
            cookies: window.sessionStorage.getItem('cookies') ?? '',
        });
    }

    cookieOnChange = (object: any) => {
        let cookies = this.state.cookies;
        if (cookies.indexOf(object.target.id) > -1) {
            cookies = cookies.replace(object.target.id, '');
        } else {
            cookies += object.target.id;
        }
        this.setState({ cookies: cookies });
    }

    handleCookie = (accepted: boolean, cookies: string) => {
        if (!accepted) {
            cookies = 'cookieshandled';
        } else {
            if (cookies.indexOf('cookieshandled') < 0) {
                cookies += 'cookieshandled';
            }
        }
        window.sessionStorage.setItem('cookies', cookies);
        this.setState({ cookies: cookies });
        if (this.props.onChange) {
            this.props.onChange();
        }
    }

    render() {
        return (
            <div id={this.props.id} className={`cookiebar ${this.props.theme}`}>
                <div className='cookiebarRow'>
                    <Form.Check className='cookiebarItem' type='checkbox' onChange={this.cookieOnChange} id='functional' label='Funktionale Cookies' checked={true} disabled />
                    <Form.Check className='cookiebarItem' type='checkbox' name='group_cookies' onChange={this.cookieOnChange} id='googlemaps' label='Google Maps' checked={this.state.cookies.indexOf('googlemaps') > -1} />
                </div>
                <div className='cookiebarRow'>
                    <Button className='cookiebarItem' onClick={() => { this.handleCookie(true, this.state.cookies); }} variant='success' size='sm'>Akzeptieren</Button>
                    <Button className='cookiebarItem' onClick={() => { this.handleCookie(false, this.state.cookies); }} variant='link' size='sm'>Ablehnen</Button>
                </div>
            </div>
        );
    }

    // function setCookie(cname, cvalue, exdays) {
    //     const d = new Date();
    //     d.setTime(d.getTime() + (exdays*24*60*60*1000));
    //     let expires = "expires="+ d.toUTCString();
    //     document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    // }

    // function getCookie(cname) {
    //     let name = cname + "=";
    //     let decodedCookie = decodeURIComponent(document.cookie);
    //     let ca = decodedCookie.split(';');
    //     for(let i = 0; i <ca.length; i++) {
    //       let c = ca[i];
    //       while (c.charAt(0) == ' ') {
    //         c = c.substring(1);
    //       }
    //       if (c.indexOf(name) == 0) {
    //         return c.substring(name.length, c.length);
    //       }
    //     }
    //     return "";
    // }

    // function checkCookie() {
    //     let user = getCookie("username");
    //     if (user != "") {
    //       alert("Welcome again " + user);
    //     } else {
    //       user = prompt("Please enter your name:", "");
    //       if (user != "" && user != null) {
    //         setCookie("username", user, 365);
    //       }
    //     }
    // } 
}
